
/* Home Page Section Start */
.tf-sl3.wd-review-job.thumb2 .tes-box {
  bottom: 0.5%;
  padding: 0px;
  padding-right: 12px;
}
.tf-slider .chart-box {
  padding: 0px 10px 5px;
  top: -20%;
  right: -55.5%;
  padding-bottom: 11px;
}
.tf-slider.sl3 {
  padding-top: 65px;
  padding-bottom: 40px;
}
.tf-sl3.wd-review-job.thumb2 .thumb .box2 .markk {
  position: absolute;
  bottom: -20%;
  right: -25%;
}
.tf-sl3.wd-review-job.thumb2 .thumb .box1 .markk {
  position: absolute;
  top: -9%;
  right: -6%;
}
.wd-review-job.thumb2 .icon3 {
  bottom: 6%;
  right: 28.5%;
}
/* Home Page Section End*/

/* Input Field Error Start */
/* Styling for the error message */
.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
  font-weight: bold;
}

/* You can also style the input fields when there is an error */
input:invalid {
  border-color: red;
}

input:focus:invalid {
  outline-color: red;
}

/* Optional: You can highlight the input fields when they are invalid */
input:invalid {
  border: 1px solid red;
}

/* For password eye icon state, you can also apply color change */
.password-addon {
  color: #999;
  cursor: pointer;
}

.password-addon.icon-eye {
  color: green; /* Change color when the password is visible */
}

.password-addon.icon-eye-off {
  color: red; /* Change color when the password is hidden */
}

/* Input Field Error End */
/* stand input styles */
.standard-input{
  border-radius: 6px;
  width: 100%;
}